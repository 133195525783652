import React from "react"

const TransitionGrid = () => (
    <ul className="transition__grid">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
)

export default TransitionGrid