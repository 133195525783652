import React from "react"

const Footer = () => (
    <footer className="footer">
        <div className="container footer__container">
            <div className="footer__heading-container">
                <h4 className="footer__heading">Contact</h4>
            </div>
            <a href="mailto:stevendana86@gmail.com" className="footer__email">
                <div>
                    <span>stevendana86@gmail.com</span>
                    <span>stevendana86@gmail.com</span>
                    <span>stevendana86@gmail.com</span>
                </div>
            </a>
        </div>
    </footer>
)

export default Footer