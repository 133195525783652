// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-champion-windows-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\champion-windows.js" /* webpackChunkName: "component---src-pages-champion-windows-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-exchange-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\knowledge-exchange.js" /* webpackChunkName: "component---src-pages-knowledge-exchange-js" */),
  "component---src-pages-ooh-icon-system-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\ooh-icon-system.js" /* webpackChunkName: "component---src-pages-ooh-icon-system-js" */),
  "component---src-pages-ripple-cube-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\ripple-cube.js" /* webpackChunkName: "component---src-pages-ripple-cube-js" */),
  "component---src-pages-two-bards-js": () => import("C:\\Users\\Steven\\Sites\\teebz64.github.io\\src\\pages\\two-bards.js" /* webpackChunkName: "component---src-pages-two-bards-js" */)
}

